<template>
  <header class="header--container" ref="headerLayout">
    <div class="d-flex justify-content-between align-items-center container">
      <div class="header--menu" @click="openSidebar()">
        <img src="@/assets/img/icons/menu.svg" />
      </div>
      <div class="header--logo">
        <router-link to="/">
          <img :src="logoUrl" alt="BeliRumah" />
        </router-link>
      </div>
      <div class="d-flex align-items-center">
        <div class="hide--in-mobile">
          <div class="d-flex align-items-center">
            <!--            <header-project-type></header-project-type>-->
            <!--            <router-link-->
            <!--              to="/project/add"-->
            <!--              class="mr-4-5"-->
            <!--              @click="!loggedIn ? showLogin() : goToAddProject('R')"-->
            <!--            >-->
            <!--              {{ $t('general.entrustProperty') }}-->
            <!--            </router-link>-->
            <!--            <div class="mr-4-5" style="cursor: pointer;" @click="goToSearch('rumah')">-->
            <!--              {{ $t('general.buyHouse') }}-->
            <!--            </div>-->
            <!--            <div class="mr-4-5" style="cursor: pointer;" @click="goToSearch('apartemen')">-->
            <!--              {{ $t('general.buyApartment') }}-->
            <!--            </div>-->
            <a href="https://komunitas.belirumah.co" target="_blank" class="mr-4-5">
              {{ $t('footer.company.forum') }}
            </a>
            <a href="https://blog.belirumah.co" target="_blank" class="mr-4-5">
              {{ $t('footer.company.blog') }}
            </a>
            <a href="/kalkulator" class="mr-4-5">
              {{ $t('general.mortgage') }}
            </a>
            <a :href="`${agentWebsiteUrl}/agent-offerings`" class="mr-4-5">
              {{ $t('general.placeAds') }}
            </a>
            <!--            <a href="/mitra-agen" class="mr-4-5">-->
            <!--              {{ $t('general.agentPartner') }}-->
            <!--            </a>-->
            <!--            <a href="/mitra-proyek" class="mr-4-5">-->
            <!--              {{ $t('general.developerPartner') }}-->
            <!--            </a>-->
          </div>
        </div>
        <div class="buttons" v-if="loggedIn">
          <!--            <header-inbox></header-inbox>-->
          <!--            <header-notifications></header-notifications>-->
          <header-user></header-user>
        </div>
        <div class="hide--in-mobile" v-else>
          <div class="btn btn-primary" @click="showLogin">
            {{ $t('loginRegister.title') }}
          </div>
        </div>
      </div>
    </div>
    <header-categories></header-categories>
  </header>
</template>

<script>
// import HeaderProjectType from '@/components/layouts/partials/header-project-type';
// import HeaderInbox from '@/components/layouts/partials/header-inbox';
// import HeaderNotifications from '@/components/layouts/partials/header-notifications';
import HeaderUser from '@/components/layouts/partials/header-user';
import HeaderCategories from '@/components/layouts/partials/header-categories';
import { mapState } from 'vuex';

export default {
  mixins: [],
  components: {
    // HeaderProjectType,
    // HeaderInbox,
    // HeaderNotifications,
    HeaderUser,
    HeaderCategories,
  },
  computed: {
    ...mapState({
      logoUrl: (state) => state.global.logoUrl,
      loggedIn: (state) => state.global.loggedIn,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
    }),
    agentWebsiteUrl() {
      return process.env.VUE_APP_AGENT_URL;
    },
  },
  async mounted() {
    this.$store.commit('global/SET_HEADER_LAYOUT', this.$refs.headerLayout);
    await this.$store.dispatch('v2/masters/getPropertyTypes');
  },
  methods: {
    showLogin() {
      this.$modal.show('modal-login');
    },
    // eslint-disable-next-line no-unused-vars
    async goToAddProject(type) {
      this.$router.push({
        path: `/project/add`,
        // query: query,
      });
    },
    openSidebar() {
      this.$store.commit('global/SET_MAIN_SIDEBAR', true);
    },
    async goToSearch(propertyTypeName) {
      try {
        let propertyType = null;
        if (propertyTypeName && propertyTypeName.toLowerCase() !== 'semua-tipe-properti') {
          propertyType = await this.propertyTypes.find(function (item) {
            return item && item.name
              ? item.name.toLowerCase() === propertyTypeName.toLowerCase()
              : false;
          });
          if (propertyType) propertyType = JSON.parse(JSON.stringify(propertyType));
        }
        this.$store.commit('v2/search/SET_SELECTED_PROPERTY_TYPE', propertyType);
        await this.$store.dispatch('v2/search/goToSearchPage', {
          router: this.$router,
          params: this.$route.params,
          query: {},
        });
      } catch (e) {
        console.log('ERROR GOING TO SEARCH PAGE: ', e);
      }
    },
  },
};
</script>
