<template>
  <div class="header--categories">
    <div class="container d-flex align-items-center">
      <div class="all--categories">
        <div v-for="(item, index) in getPropertyTypesForHeader()" :key="`main-cat-header-${index}`">
          <router-link :to="generateSearchLink(item)">{{ item.name }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  name: 'header-categories',
  mixins: [HelperMixin],
  computed: {
    ...mapState({
      itemTypes: (state) => state.global.itemTypes,
      propertyTypes: (state) => state.v2.masters.propertyTypes,
    }),
  },
  async mounted() {
    let promises = [];
    if (!this.propertyTypes || this.propertyTypes.length === 0)
      promises.push(this.$store.dispatch('v2/masters/getPropertyTypes'));
    await Promise.all(promises);
  },
  methods: {
    getPropertyTypesForHeader() {
      let newList = [];
      if (this.propertyTypes) {
        for (let i = 0; i < this.propertyTypes.length; i++) {
          if ([null, 1, 2, 4, 5].includes(this.propertyTypes[i].id)) {
            newList.push(this.propertyTypes[i]);
          }
        }
      }
      return newList;
    },
  },
};
</script>

<style scoped></style>
